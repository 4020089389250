const jsQuestions = [
    {
        question: 'Какво е JavaScript и какво е неговото предназначение?',
        answer: 'JavaScript е високопроизводителен, интерпретируем, многопарадигмен език за програмиране, използван за добавяне на интерактивност и динамични функции към уеб страници и приложения.'
    },

    {
        question: 'Какво представлява асинхронното програмиране в JavaScript и защо е важно?',
        answer: 'Асинхронното програмиране в JavaScript позволява изпълнението на неблокиращи операции, като мрежови заявки и обработка на събития, което гарантира по-отзивчиви и бързи уеб приложения.'
    },
    {
        question: 'Какви са предимствата на използването на JavaScript за уеб разработка?',
        answer: 'JavaScript е незаменим за уеб разработката, като осигурява богат набор от библиотеки и рамки (React, Angular, Vue.js), подобрява потребителското изживяване чрез динамично съдържание и интерактивност, и предлага висока съвместимост с различни браузъри и платформи.'
    },
    {
        question: 'Как JavaScript се интегрира с други технологии и платформи?',
        answer: 'JavaScript лесно се интегрира с различни бази данни, API и сървърни технологии (Node.js), което го прави универсално решение за пълностек разработка, осигурявайки безпроблемна комуникация между фронтенд и бекенд компоненти.'
    }
];

export default jsQuestions;
