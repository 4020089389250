import img from '../../../styles/44.png';

const reasonsToChooseSQL = {
    dataManipulation: " SQL предоставя мощни инструменти за манипулиране на данни в бази данни.",
    dataQuery: " Лесно и бързо извличане на информация с SQL заявки.",
    dataModeling: " Създаване на структурирани и ефективни модели на данни.",
    databaseIntegration: " Възможност за интеграция с различни бази данни и системи.",
    dataSecurity: " Вградена защита на данните и управление на достъпа.",
    scalability: " Лесно скалиране на бази данни с повече информация.",
    careerOpportunities: " Голям брой възможности за кариера в областта на управлението на данни.",
};


const sql = {
    image: img,
    langName: 'SQL',
    information: [
        reasonsToChooseSQL.dataManipulation,
        reasonsToChooseSQL.dataQuery,
        reasonsToChooseSQL.dataModeling,
        reasonsToChooseSQL.databaseIntegration,
        reasonsToChooseSQL.dataSecurity,
        reasonsToChooseSQL.scalability,
        reasonsToChooseSQL.careerOpportunities
    ],

}

export default sql