import img from '../../../styles/csharp1.png';

const csharp = {
    image: img,
    langName: 'C#',
    information: [
        " <b> Многостранност: </b> Подходящ за уеб, настолни, мобилни приложения и игри.",
        " <b> Среда за разработка: </b> Visual Studio осигурява мощна среда за разработка.",
        "<b> ООП Поддръжка: </b> Силна поддръжка на обектно-ориентирано програмиране.",
        "<b> Стандартна библиотека: </b> Изчерпателна библиотека за общи задачи.",
        "<b> LINQ: </b>  Улеснено манипулиране на данни чрез интегрирани запитвания.",
        "<b> Кариера: </b>  Много възможности за работа в софтуерната разработка."
    ],


}

export default csharp