const csharp = [
    {
        question: 'Защо да уча C# и .NET?',
        answer: 'Ученето на C# и .NET предоставя възможност за разработка на уеб, десктоп, мобилни и игрови приложения. Те са широко използвани и отварят много кариерни възможности.'
    },
    {
        question: 'Какво прави езикът C# подходящ за начинаещи?',
        answer: 'C# е структурен и лесен за научаване език със строга типизация и богата екосистема, което го прави прозрачен и подходящ за новаци.'
    },
    {
        question: 'Какво представлява програмният език C#?',
        answer: 'C# е разработен от Microsoft през 2000 г. Той е структурен, мулти-парадигмен, строго-типизиран, декларативен, императивен, функционален, обектно-ориентиран, компонентно-ориентиран език с общо предназначение.'
    },
    {
        question: 'Какви са основните предимства на платформата .NET?',
        answer: '.NET предоставя интегрирано средство за разработка и изпълнение на софтуер с богата стандартна библиотека, средства за сигурност и инструменти за управление на изключенията.'
    },
    {
        question: 'Какво представлява асинхронното програмиране в C#?',
        answer: 'Асинхронното програмиране позволява на приложенията да изпълняват неблокиращи операции и да останат отзивчиви, полезно за мрежови операции и вход/изход.'
    },
    {
        question: 'Как се работи с бази данни в C#?',
        answer: 'C# поддържа различни библиотеки за работа с бази данни. Entity Framework е популярна ORM технология, която улеснява взаимодействието с базите данни.'
    },
];

export default csharp;
