import { Link } from 'react-router-dom'
import visualStudio from '../../../styles/photos/blog01.png'
import dynamicProggraming from '../../../styles/photos/dynamic.png'
import csharpBegginers from '../../../styles/photos/csharpBlog.jpg'
import reactPhoto from '../../../styles/photos/reactBlog.png'
import learnJs from '../../../styles/photos/learnJS.png'
const blogData = [
    {
        id: 5,
        title: "Защо да изберем JavaScript: Врата към Света на Динамичното Програмиране",
        imageSrc: learnJs,
        content: (
            <div>
                JavaScript присъства на тази страница и на всяка уеб страница, която посещавате. Той е навсякъде,
                което го прави един от <strong>най-разпространените езици за програмиране</strong>.
                <br />
                Това е език, който работи във всички уеб браузъри. Можете да навлезете направо в JavaScript,
                без да се налага да прекарвате време в създаване на сложни среди или да изтегляте
                редактори и компилатори на код (които след това също ще трябва да се научите да използвате)!<br />
            </div>),
        firstQuestion: "JavaScript е лесен за научаване",
        firstParagraph: (
            <div>
                JavaScript е един от езиците от високо ниво. Това означава, че не е необходимо да се притеснявате за много сложни неща,
                като например управлението на паметта. Целта на езиците от високо ниво е да ви направят бързо продуктивен програмист,
                като същевременно ви отнемат част от възможностите, които имате в езиците от ниско ниво. Можете да се съсредоточите върху синтаксиса
                и по-достъпни за възприемане концепции, без да се притеснявате прекалено много за неща като производителност. <br />
                <br />

                Освен това синтаксисът на JavaScript е доста <strong>логичен и прост</strong>.
                Той наподобява донякъде английския език, така че ако прочетете тази статия, сте на път да започнете своето приключение с
                JavaScript и да откриете нови хоризонти само за няколко месеца.
            </div>
        ),
        secondQuestion: "JavaScript е универсален",
        secondParagraph: (
            <div>
                <strong>JavaScript е използван за 98% от сайтовете в световен мащаб</strong>, което е показателен за популярността на езика.
                Ако искате да се занимавате с front-end разработка, изучаването на JavaScript е задължително. Няма значение дали работите
                в Google или в малък стартъп;
                езикът е навсякъде в уеб и е огромно предимство да го знаете.<br />

                <br />

                Но това е само част от картината. Съвременният JavaScript се използва почти навсякъде, не само в интернет.
                Искате ли да създадете <strong>мобилно приложение</strong>? Няма проблем; можете да го направите с JavaScript.
                Приложение за <strong>настолни компютри</strong>? Лесно; JavaScript може да се справи с това.
                Може би не обичате да <strong>изграждате интерфейси</strong>, а вместо това предпочитате да изграждате <strong>бекенд логика</strong>?
                Перфектно, можете да го направите с JavaScript. Ако наистина искате, можете да го използвате дори за машинно обучение.<br />
            </div>),

        thirdQuestion: "JavaScript се търси",
        thirdParagraph: (
            <div>
                Според доклада на DevSkiller <strong>JavaScript е най-търсеното техническо умение</strong>. Освен това 72% от компаниите търсят разработчици на JavaScript.
                Нещо повече, повечето разработчици ще получат оценка на ИТ уменията за JavaScript, независимо от основната им насоченост.
                <strong>Езикът бързо се превръща в задължително умение</strong>.<br />
                <br />

                Заплатите на разработчиците на JavaScript също се увеличават.
                В зависимост от държавата заплатите се различават много, но за да ви дам известна представа,
                средната годишна заплата е 110 591 USD в САЩ, <strong>44 334 BGN в България</strong>
                и 51 457 GBP във Великобритания.
            </div>),

        conclusionTitle: "Откъде да започнеш",
        conclusionParagraph: (
            <div>
                Разбираш ли, че с научаването на JavaScript, ще придобиеш не само ключови умения, но и възможността да работиш с водещи технологии?
                Това отваря безброй врати към успешна професионална кариера,
                особено като <strong>full-stack разработчик</strong>.
                <br />

                <strong>Аз съм тук, за да ти помогна</strong> да направиш тaзи първа важна стъпка.
                Започни с <strong>частните уроци по JavaScript</strong> и се приготви за невероятно пътешествие в света на програмирането. <br />
                Запиши час за разговор още {' '}
                <Link to="/calendar" className="link" style={{ color: '#71F483' }}>СЕГА</Link>.
            </div>
        ),
        date: `06 Oct, 2023`,
        author: "Иван Иванов",
    },
    {
        id: 4,
        title: "Какво представлява React?",
        imageSrc: reactPhoto,
        content: "React е библиотека за създаване на потребителски интерфейси(UI) с помощта на JavaScript.Тя е разработена от Facebook и се използва широко в уеб разработката за създаване на динамични и интерактивни уеб приложения. ",

        firstQuestion: "Въздействието и развитието на React в уеб разработката",
        firstParagraph: `React, известен също като React.js, е един от най-добрите фреймуърци на JavaScript в категорията front-end. 
        Това е фронт-енд JavaScript библиотека с отворен код, създадена от екип от разработчици на Facebook под ръководството на 
        Джордан Уолк през 2011 г., а през юни 2013 г. става отворен код. Първоначалният прототип е наречен "FaxJS" и за първи път 
        е експериментиран в Newsfeed на Facebook. \n
         React може да се счита за един от най-големите влиятелни разрушители в областта на 
        уеб разработката, който донесе истински пробив в оформянето на уеб приложенията, които виждаме днес.React въведе 
        компонентно-базиран, функционален и декларативен стил на програмиране за създаване на интерактивни потребителски интерфейси
        за уеб приложения предимно от една страница. React осигурява изключително бързо рендиране, като използва "Virtual DOM". DOM 
        е съкращение от Document Object Model, който рендира само тези компоненти, които са се променили, вместо да рендира цялата 
        страница. Друга ключова характеристика на React е по-простият синтаксис на JSX вместо на JavaScript.`,

        secondQuestion: "Плюсове за използването на React",
        secondParagraph: (
            <div>
                <ul>
                    <li>
                        Компоненти за многократна употреба - Базираният на компоненти подход помага на разработчиците да
                        импортират или използват повторно компоненти на потребителския интерфейс, без да се потят, вместо да ги кодират от нулата.
                        Лесна интеграция - Позволява лесна интеграция с други front-end и back-end рамки,
                        като популярната PHP рамка Laravel, за да работи безпроблемно и без затруднения. <br />
                    </li>
                    <li>
                        Посока на потока от данни - Основната разлика е, че React.js следва архитектура с насочен надолу поток от данни.
                        Това гарантира, че подчинен елемент не може да повлияе на родителския,
                        което прави кода по-стабилен и по-малко податлив на грешки.<br />
                    </li>
                    <li>
                        По-прост синтаксис - Използва разширението JSX JavaScript вместо обичайния JavaScript - синтаксис,
                        подобен на HTML, който прави кода по-лесен за четене, разбиране и поддръжка.<br />
                    </li>
                    <li>
                        Виртуален DOM - Създава виртуално представяне или копие на DOM, наречено Virtual DOM или vDOM.
                        React сравнява виртуалния DOM с реалния DOM, за да визуализира само компонентите, които са се променили,
                        вместо да визуализира цялата страница.
                        Това е ключът към светкавичната производителност на React.<br />
                    </li>
                    <li>
                        Непрекъснато усъвършенстване - React е в състояние на постоянна еволюция и усъвършенстване, като добавя
                        ключови нови функции като Hooks, Fiber, Concurrent Mode, Suspense и др. за намаляване на шаблонния код,
                        подобряване на едновременността и бързото визуализиране и повишаване на производителността, което го прави
                        силен претендент в категорията на най-добрите JavaScript фреймуърки.<br />
                    </li>
                </ul>
            </div>),

        thirdQuestion: "Минуси за използването на React",
        thirdParagraph: (
            <div>
                <ul>
                    <li>
                        Въпреки че JSX е по-опростен синтаксис, който е по-лесен за четене и разбиране,
                        той може да представлява допълнителна бариера за обучение на новите разработчици.<br />
                    </li>
                    <li>
                        Много разработчици смятат, че React има много по-стръмна крива и раздута сложност
                        от ключови конкурентни рамки като Vue.js или други JavaScript frameworks.
                    </li>
                    <li>
                        React често се сблъсква с оплаквания от лошата документация, която не може да навакса с
                        новите версии и функции, което принуждава разработчиците.<br />
                    </li>
                    <li>
                        Занимава се с View Layer на MVC модела, като не оставя друга възможност,
                        освен да разчита на други технологии за справяне с Model и Controller Layer.
                    </li>
                    <li>
                        Непрекъснато усъвършенстване - React е в състояние на постоянна еволюция и усъвършенстване, като добавя
                        ключови нови функции като Hooks, Fiber, Concurrent Mode, Suspense и др. за намаляване на шаблонния код,
                        подобряване на едновременността и бързото визуализиране и повишаване на производителността, което го прави
                        силен претендент в категорията на най-добрите JavaScript фреймуърки.<br />
                    </li>
                </ul>
            </div>),

        conclusionTitle: "Заключение",
        conclusionParagraph: (
            <div>
                React е мощна и влиятелна JavaScript библиотека, която промени начина, по който разработваме уеб приложения.
                Независимо дали сте нов разработчик или опитен професионал, React предлага множество предимства, включително
                компонентно-базираното програмиране, виртуалния DOM и обширната общност. Това прави React един от най-популярните
                и предпочитани инструменти за създаване на съвременни уеб приложения.<br />

                Въпреки някои от минусите и предизвикателствата, свързани с React, той остава силна и иновативна технология,
                която продължава да се развива и подобрява с всяка нова версия. С богатите възможности и общността зад него,
                React остава водещ избор за много разработчици.<br />
                Независимо от вашите цели и проекти, React може да бъде мощният инструмент, който ви помага да създавате
                иновативни и интерактивни уеб приложения с лекота. Затова, ако търсите инструмент за фронтенд разработка
                с бързина, надеждност и поддръжка от активна общност, React е безспорен избор.
            </div>
        ),
        date: `13 Sept, 2023`,
        author: "Иван Иванов",
    },
    {
        id: 1,
        title: "Visual Studio срещу Visual Studio Code - Каква е разликата между тези редактори на код на IDE?",
        imageSrc: visualStudio,
        content: 'Visual Studio" и "Visual Studio Code" не са едно и също нещо. Visual Studio е интегрирана среда за разработка (IDE), а Visual Studio Code е богат текстов редактор като Sublime Text и Atom.',

        firstQuestion: 'Какво е Visual Studio?',
        firstParagraph: `Visual Studio е пуснато за първи път през 1997 г. от Microsoft. Това е интегрирана среда за разработка
        (IDE) за разработване, редактиране и отстраняване на грешки в уебсайтове, уеб и мобилни приложения, както и в облачни услуги.

            Тъй като това е IDE, в нея са включени помощни програмистки инструменти като дебъгер, компилатор, intellisence и др.
            
            Visual Studio се предлага с вградена поддръжка за C# и .NET. Поддържа и други езици 
            за програмиране като C, C++, Python, F#, уеб езици (HTML, CSS, JavaScript) и много други. Поддръжката на Java беше премахната 
            още във Visual Studio 2017.
            
            Visual Studio работи под Windows и Mac. То има 3 издания - community, professional и enterprise. Версията community е 
            безплатна, докато версиите professional и enterprise не са.
            
            Инсталацията е доста по-стабилна в Windows, отколкото в Mac. Така че при Windows може да се наложи да изтеглите повече 
            от 42 GB в зависимост от това какво искате да направите.`,

        secondQuestion: 'Какво е Visual Studio Code?',
        secondParagraph: `Visual Studio Code (наричано още VS Code) е мини версия на Visual Studio. Това е лек текстов редактор
         с отворен код, достъпен за Windows, Mac и Linux. Съществува и уеб версия, достъпна на адрес https://vscode.dev/.

            VS Code се предлага с вградена поддръжка за JavaScript, TypeScript и Node JS, но можете да го използвате,
             за да програмирате на всеки език, който искате. Всичко, което трябва да направите, е да изтеглите съответните разширения.
            
            Някои от разширенията са направени от Microsoft, но много други са разширения на трети страни.
            
            За разлика от Visual Studio, не ви е необходимо много място, за да изтеглите VS Code. Възможно е да не се нуждаете от повече от 200 MB дисково пространство, за да го изтеглите.
            
            Тъй като по подразбиране поддържа JavaScript, TypeScript и Node JS, получавате и дебъгер и интелигиенция. Но за да получите intellisence, компилатор и дебъгъри за други езици, трябва да изтеглите съответните разширения.
            
            Вече знаете, че Visual Studio е IDE, а Visual Studio Code е текстов редактор. Затова нека обобщим основните им разлики по-нататък.`,

        thirdQuestion: 'Каква е разликата между "Visual Studio" и "Visual Studio Code"?',
        thirdParagraph: `Отдавна се води дебат за това кое е по-добро и кое да се избере между Visual Studio и Visual Studio Code. Е, това зависи от това какво правите.

            Ако разработвате изключително с език, поддържан от Visual Studio, като C#, C, C++, Python и други, Visual Studio или други подходящи IDE вероятно са най-добрият вариант за вас.
            
            Но дори ако разработвате на тези езици, но ви е необходим фронтенд React, Vue или Angular, VS code може да е най-добрият вариант за вас.
            
            Ако работите в екип, може да ви предоставят корпоративната версия на Visual Studio или някоя друга IDE, която кореспондира с езика, с който работите. Например PyCharm за Python и IntelliJ Idea за Java.
            
            Ако използвате Linux, трябва да изберете Visual Studio Code или някоя друга IDE, освен Visual Studio. Това е така, защото Visual Studio не работи под Linux.
            
            Ако сте от хората, които обичат да настройват редактора си по свой вкус, просто изберете VS Code, защото е силно персонализиран. Също така вероятно трябва да изберете VS Code, ако смесвате технологии.`,


        conclusionTitle: `Заключение`,
        conclusionParagraph: `В тази статия бяха показани разликите между Visual Studio и VS Code, както и какво представляват двете програми поотделно.

            Дебатът никога не трябва да бъде кой е по-добър от другия, а кой е най-добър за това, което искате да направите, или от което се нуждаете. Ето защо разгледахме някои сценарии, които могат да ви подтикнат да изберете единия вместо другия.`
        ,
        date: `10 Sept, 2023`,
        author: "Иван Иванов",

    },
    {
        id: 2,
        title: "Научете техники за динамично програмиране в Java",
        imageSrc: dynamicProggraming,
        content: "Динамичното програмиране е мощна техника, която е крайъгълен камък в света на алгоритмите и компютърните науки. Това е метод, който разделя проблемите на по-малки, по-управляеми подпроблеми, като решава всяка от тях само веднъж и съхранява решенията им в случай, че са необходими отново. Този подход е особено полезен за оптимизационни проблеми, при които се търси най-доброто решение сред набор от възможни решения.",

        firstQuestion: "Какво представлява динамичното програмиране?",
        firstParagraph: 'Динамичното програмиране е метод за решаване на сложни проблеми чрез разделянето им на по-прости подпроблеми. Това е начин за решаване на проблеми чрез използване на решения на по-малки случаи на един и същ проблем. Основната идея на динамичното програмиране е доста проста. Най-общо казано, за да решите даден проблем, решавате няколко подпроблема. Динамичното програмиране се използва, когато подпроблемите не са независими, т.е. когато подпроблемите имат общи подпроблеми. В този контекст алгоритмите "разделяй и владей" вършат повече работа от необходимото, като многократно решават общите подзадачи. Алгоритмите за динамично програмиране запомнят минали резултати и ги използват за намиране на нови резултати.',

        secondQuestion: "Кога се използва динамично програмиране?",
        secondParagraph: 'Динамичното програмиране често се използва в оптимизационни задачи, при които трябва да се намери най-доброто решение сред набор от възможни решения. То е особено полезно, когато даден проблем има припокриващи се подпроблеми. Вместо да се изчисляват многократно решенията на тези подзадачи, динамичното програмиране предлага да се реши всяка от тези подзадачи само веднъж, да се запазят решенията им и да се върне запазеното решение, когато същата подзадача се срещне отново.',

        thirdQuestion: "Динамично програмиране в интервюта",
        thirdParagraph: "Динамичното програмиране е любима тема в интервютата за програмиране. Интервюиращите обичат да проверяват разбирането, аналитичните умения и способностите за решаване на проблеми на кандидатите, като използват задачи за динамично програмиране. Не става въпрос само за познаване на техниката, но и за разбиране кога и как да я използвате. Овладяването на динамичното програмиране може да ви даде значително предимство в интервютата за програмиране.",

        conclusionTitle: "",
        conclusionParagraph: "",

        date: `10 Sept, 2023`,
        author: "Иван Иванов",
    },
    {
        id: 3,
        title: "Защо C# е перфектният избор за начинаещи?",
        imageSrc: csharpBegginers,
        content: "Подобно на други езици за програмиране с общо предназначение, C# може да се използва за създаване на редица различни програми и приложения: мобилни приложения, настолни приложения, услуги в облака, уебсайтове, корпоративен софтуер и игри. Много и много игри. Въпреки че C# е забележително гъвкав, има три области, в които се използва най-често.",

        firstQuestion: "Какво е C#?",
        firstParagraph: "C# е съвременен език за програмиране с общо предназначение, който може да се използва за изпълнение на широк спектър от задачи и цели, обхващащи различни професии. C# се използва предимно в рамките на Windows .NET, въпреки че може да се прилага и в платформа с отворен код. Този изключително универсален език за програмиране е обектно-ориентиран език за програмиране (ООП) и е сравнително нов в играта, но е надежден за публиката.",

        secondQuestion: "За какво се използва C#?",
        secondParagraph: (
            <div>
                <ul>
                    <li>
                        <strong>Приложения за Windows</strong> <br />
                        C# е създаден от Microsoft за Microsoft, така че е лесно да се разбере защо се
                        използва най-често за разработване на настолни приложения за Windows. Приложенията
                        на C# изискват рамката Windows .NET, за да функционират по най-добрия начин, така че
                        най-силната употреба на този език е разработването на приложения и програми, които са
                        специфични за архитектурата на платформата на Microsoft.
                    </li>
                    <li>
                        <strong>C# за игри</strong> <br />
                        C# може би е един от най-добрите езици за програмиране за игри. Този език се използва активно
                        за създаване на любими на феновете игри като Rimworld на енджина Unity Game Engine.

                        В случай че все още не знаете, Unity е най-популярният наличен енджин за игри, на който са създадени повече от
                        една трета от най-добрите и най-често играните игри в индустрията. C# се интегрира безпроблемно с енджина на Unity и може да
                        се използва на почти всяко съвременно мобилно устройство или конзола благодарение на междуплатформени технологии като Xamarin.
                    </li>
                    <li>
                        <strong>C# за разработка на уебсайтове</strong> <br />

                        C# често се използва за разработване на професионални, динамични уебсайтове на платформата .NET или на софтуер с отворен код.
                        Така че, дори и да не сте почитатели на архитектурата на Microsoft, можете да използвате C# за създаване на напълно функционален
                        уебсайт. Тъй като този език е обектно-ориентиран, той
                        често се използва за разработване на уебсайтове, които са невероятно ефективни, лесно мащабируеми и лесни за поддръжка.<br />
                    </li>
                </ul>
            </div>),

        thirdQuestion: "Предимства на програмирането със C#",
        thirdParagraph: (
            <div>
                <ul>
                    <li>
                        <strong>Програмирането със C# може да ви спести време</strong> <br />
                        Може би най-голямото предимство е колко време можете да спестите, като използвате C# вместо друг език за програмиране.
                        Тъй като C# е статично типизиран и лесен за четене, потребителите могат да очакват да прекарват по-малко време в търсене
                        на малки грешки, които нарушават функционирането на приложението.

                        C# също така набляга на простотата и ефективността, така че програмистите могат да прекарват по-малко време в
                        писане на сложни купчини код, които се използват многократно в целия проект. Допълнете всичко това с обширна банка
                        памет и ще получите ефективен от гледна точка на времето език, който лесно може да
                        намали работното време и да ви помогне да спазите кратките срокове, без да изпивате      третата чаша кафе в 2:00 ч. сутринта.
                    </li>
                    <li>
                        <strong>C# е лесен за изучаване</strong> <br />
                        Освен времето, което можете да спестите по време на разработването на проекта, ще прекарате и по-малко време в изучаването на
                        C# в сравнение с по-трудните езици за програмиране. Благодарение на своята простота и лесни за използване функции, C# предлага
                        сравнително ниска крива на обучение за начинаещи.

                        Този език е чудесна първа стъпка в областта и предоставя на начинаещите разработчици удобен начин да се запознаят с програмирането, без да се
                        обезсърчават и претоварват.
                    </li>
                    <li>
                        <strong>C# е обектно-ориентиран</strong> <br />

                        C# е изцяло обектно-ориентиран, което е рядко срещана характеристика за език за програмиране.
                        Много от най-разпространените езици включват в някаква степен обектна ориентация, но много малко от тях
                        са постигнали мащабите на C#, без да загубят благоволението на хората.

                        Обектно-ориентираното програмиране (или ООП) има много различни предимства, като например ефективност и гъвкавост,
                        за да назовем само някои от тях. Някои разработчици, които не са запознати с ООП, може да се чувстват малко неохотно
                        да изберат нов език с толкова силен
                        акцент върху него, но не се притеснявайте: разбирането на обектно-ориентираното програмиране не е толкова трудно.
                    </li>
                </ul>
            </div>),

        conclusionTitle: "Заключение",
        conclusionParagraph: (
            <div>
                Използването на C# може да ви спести време и да улесни разработката на проекти, благодарение на
                неговата простота и ефективност. Този език е подходящ за създаване на настолни приложения за Windows,
                игри с използване на енджина Unity, както и динамични уебсайтове. Независимо от вашия проект,
                C# може да ви помогне да постигнете вашите цели.<br />

                Неговата ефективност и леснота на изучаване го правят перфектния избор за начинаещи програмисти.
                Освен това, като обектно-ориентиран език, C# ви предоставя силни инструменти за разработка и
                гъвкавост, които можете да използвате за успешния старт и надеждна кариера в света на програмирането.<br />

                Независимо от вашите цели и интереси, C# е език, който заслужава внимание и изучаване.
                С използването на C#, вие се въоръжавате с инструмент, който ви позволява да създавате
                мощни и иновативни приложения и игри, а също така ви дава възможността да научите основите
                на програмирането по по-лесен и достъпен начин. Не се колебайте да се потопите в света на C# и да
                изследвате всички възможности, които този език има да предложи.<br />
            </div>
        ),

        date: `13 Sept, 2023`,
        author: "Иван Иванов",
    }


];

export default blogData;
